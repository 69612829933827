import React from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
							Things I’ve made so far.
						</div>

						<div className="subtitle projects-subtitle" style={{ color: 'black' }}>
						Here you will find a collection of projects I have worked on throughout my career, 
						either independently or as part of a larger team. These projects highlight my ability 
						to create from scratch and collaborate effectively. From solo ventures to group efforts, 
						each project represents a significant milestone, showcasing my technical skills, 
						adaptability, and passion for delivering exceptional solutions. 
						Explore this section to witness the diverse range of projects I have undertaken, 
						spanning various industries and technologies, and gain insights into my growth as a professional.
						</div>

						<div className="projects-list">
							<AllProjects />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
